/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Button} from '@material-ui/core';
import Card from '../components/content/card';
import {Highlight} from '@signatu/common-react';
import * as ConsentReact from '@signatu/consent-react';
import {anonSubject} from '@signatu/consent';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Card, {
    title: "The SaaS Privacy Platform",
    image: "/controller.png"
  }, React.createElement(_components.p, null, "Signatu is a comprehensive Software-as-a-Service (SaaS) platform for managing privacy on your sites and apps. We\nprovide tools for managing consent, monitoring cookies and 3rd parties, and creating GDPR-compliant Privacy\nPolicies. So you can give your users control, and earn their trust.")), "\n", React.createElement(Card, {
    image: "icons/check.png",
    imagePos: "right",
    title: "Consent",
    link: "product/consent/"
  }, React.createElement(_components.p, null, "When you respectfully ask your users for their consent, you build trust. Signatu aims to provide the most\ncomprehensive, easy-to-use, scalable and secure Consent Management Platform in the market. So it’s easy for you to\nbuild those trust relationships and build your business.")), "\n", React.createElement(Card, {
    image: "trace.png",
    title: "Trackerdetect",
    link: "product/trackerdetect/"
  }, React.createElement(_components.p, null, "Analyze your website and identify 3rd party cookies, beacons and scripts that can collect information from your\nusers. We also identify security flaws in your website that may increase risk of e.g., phishing to your users. And,\nwe can monitor your site(s) multiple times per day, and notify you when 3rd parties scripts are found.")), "\n", React.createElement(Card, {
    image: "generate.png",
    imagePos: "right",
    link: "product/policy/",
    title: "Privacy Policy"
  }, React.createElement(_components.p, null, "It can be challenging to inform your users about your data processing in a way that is both clear and compliant with\nthe law. Signatu provides a comprehensive questionnaire that ensures you cover all the required information in your\npolicy. Once you have completed you can publish the Privacy Policy to our global, high-speed Content Delivery\nNetwork (CDN).")), "\n", React.createElement(Card, {
    image: "icons/share.png",
    link: "collaborate/",
    title: "Collaborate in teams"
  }, React.createElement(_components.p, null, "Safeguarding personal data and repecting your users privacy is something that cannot be solved by a single person in\nan organization. Signatu has collaboration features that enable you to involve a larger part of your organization in\nthe work.")), "\n", React.createElement(Card, {
    image: "icons/apikey.png",
    title: "Developer Friendly"
  }, React.createElement(_components.p, null, "We aim to make integrating with Signatu from your website or app as easy as possible. Signatu provides a REST API,\nre-usable and composable React components, and a Javascript SDK.")), "\n", React.createElement(Highlight, {
    language: "jsx",
    live: true,
    scope: {
      anonSubject,
      ...ConsentReact
    }
  }, `
<ConsentButtons consentTarget={{subject: anonSubject(), target: "signatu.com/demo/home"}}>
 Using this React component looks easy! When your users accept or refuse a request like this, Signatu will safeguard the event for you. Go ahead, try to edit the black box, it's a live component. 
</ConsentButtons>
    `), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(Card, {
    image: "eu-flag.png",
    imagePos: "right",
    title: "GDPR & ePrivacy"
  }, React.createElement(_components.p, null, "The secure Signatu platform takes care of a lot of complex compliance details so that you don’t have to. Our legal\nexpert network reviews Privacy Policy clauses and Consent dialogs in sync with EU legal requirements.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
